import React from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { Alert, Button, Card, CardBody } from 'reactstrap'

import { number } from '../../../helpers'

export default function GeneralReportForm({ report, params, setParams, onSubmit, loading }) {
	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<Form.Group>
						<Form.Label>Año de Consulta <span className='text-danger'>*</span></Form.Label>
						<Form.Control
							as="select"
							value={params.year}
							onChange={e => setParams({ ...params, year: e.target.value })}
						>
							<option>2025</option>
							<option>2024</option>
							<option>2023</option>
							<option>2022</option>
							<option>2021</option>
							<option>2020</option>
						</Form.Control>
						<Form.Label>Mes de Consulta <span className='text-danger'>*</span></Form.Label>
						<Form.Control
							as="select"
							value={params.month}
							onChange={e => setParams({ ...params, month: e.target.value })}
						>
							<option value="01">Enero</option>
							<option value="02">Febrero</option>
							<option value="03">Marzo</option>
							<option value="04">Abril</option>
							<option value="05">Mayo</option>
							<option value="06">Junio</option>
							<option value="07">Julio</option>
							<option value="08">Agosto</option>
							<option value="09">Septiembre</option>
							<option value="10">Octubre</option>
							<option value="11">Noviembre</option>
							<option value="12">Diciembre</option>
						</Form.Control>
					</Form.Group>
					<Button color='primary' onClick={onSubmit} disabled={loading}>
						Generar Reporte{" "}
						{ loading && <Spinner size="sm" animation='border' /> }
					</Button>
				</CardBody>
			</Card>
			{ report && (
				<Card>
					<CardBody>
						<p>Visualizando el reporte con registros desde del mes <b>{report.params.month}</b> del <b>{report.params.year}</b></p>
						<p><b>Total de Registros: </b>{number(report.data.total)} ventas</p>
						<p><b>Total de Asesores: </b>{number(report.data.detail_table.length)} asesores</p>
						<p><b>Consultado el: </b>{report.data.consult_at}</p>
						<p><b>Tiendas asociadas a su usuario: </b>{report.params.user_stores}</p>
						<Alert color='warning'>
							Visualizando únicamente facturas realizadas en tiendas asociados a su usuario.
						</Alert>
					</CardBody>
				</Card>
			)}
		</React.Fragment>
	)
}