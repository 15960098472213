import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Alert, Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import { storeDownloadToken } from '../services'
import { getUri } from '../../../services/springApi'
import { handleError } from '../../../helpers'

export default function ExportResellsModal({ visible, onClose, defaultParams }) {
	const user_id = useSelector(state => state.auth.user.id)
	const [loading, setLoading] = useState(false)
	const [params, setParams] = useState(defaultParams)

	const onSubmit = () => {
		setLoading(true)
		let token = new Date().getTime()
		storeDownloadToken({ type: `Descarga Ventas Recompra Fiel`, token, user_id })
			.then(() => {
				message.info('Generando archivo en nueva pestaña')
				window.open(`${getUri()}/exports/sale_logs/${params.year}/${params.month}/${token}`)
			})
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Exportar Recompras</ModalHeader>
				<ModalBody>
					<Row>
						<Form.Group as={Col}>
							<Form.Label>Año de Consulta <span className='text-danger'>*</span></Form.Label>
							<Form.Control
								as="select"
								value={params.year}
								onChange={e => setParams({ ...params, year: e.target.value })}
							>
								<option>2025</option>
								<option>2024</option>
								<option>2023</option>
								<option>2022</option>
								<option>2021</option>
								<option>2020</option>
							</Form.Control>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Mes de Consulta <span className='text-danger'>*</span></Form.Label>
							<Form.Control
								as="select"
								value={params.month}
								onChange={e => setParams({ ...params, month: e.target.value })}
							>
								<option value="01">Enero</option>
								<option value="02">Febrero</option>
								<option value="03">Marzo</option>
								<option value="04">Abril</option>
								<option value="05">Mayo</option>
								<option value="06">Junio</option>
								<option value="07">Julio</option>
								<option value="08">Agosto</option>
								<option value="09">Septiembre</option>
								<option value="10">Octubre</option>
								<option value="11">Noviembre</option>
								<option value="12">Diciembre</option>
							</Form.Control>
						</Form.Group>
					</Row>
					<Button color='primary' onClick={onSubmit} disabled={loading}>
						Exportar Recompras{" "}
						{ loading && <Spinner size="sm" animation='border' /> }
					</Button>
					<Alert color='warning' className='mt-3 mb-0 p-2'>
						Se exportara únicamente recompras registradas en tiendas asociados a su usuario.
					</Alert>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}